<template>
  <div class="app-container" v-loading.fullscreen.lock="fullscreenLoading">
    <header-menu :showTitle="false" logoTitle="企业中心" logoRouter="/enterprise" :menuItems="menuItems" />
    <div class="app-content-wrap">
      <router-view v-if="isAuthenticated" />
    </div>
    <footer-info />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeaderMenu from "@/components/Common/HeaderMenu.vue";
import { StaffTypeEnum } from "@/utils/constant";
import FooterInfo from "@/components/Common/FooterInfo";
export default {
  components: { HeaderMenu, FooterInfo },
  name: "EnterpriseLayout",
  data() {
    return {
      isAuthenticated: false,
      fullscreenLoading: true,
      menuItems: [
        { title: "工作台", path: "/enterprise" },
        { title: "岗位管理", path: "/enterprise/job" },
        { title: "招生动态", path: "/enterprise/recruitnews" },
        { title: "课程资源", path: "/enterprise/course" },
        { title: "企业管理", path: "/enterprise/staff" },
      ]
    };
  },
  computed: {
    ...mapGetters(["employee"])
  },
  methods: {
    init() {
      const { Employer } = this.employee.find(({ StaffType, IsAdmin }) => IsAdmin && StaffType === StaffTypeEnum.Enterprise) || {};
      if (Employer) {
        this.fullscreenLoading = false;
        this.isAuthenticated = true;
        return;
      }
      this.$confirm("暂无访问权限！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showCancelButton:false,
        type: "warning"
      }).then(() => this.$router.push(`/`)).catch(err => {debugger;this.$router.push(`/`) });

    }
  },
  created() {
    this.init();
  }
};
</script>
