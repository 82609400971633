<template>
  <div class="app-footer-wrap" v-if="false">
    <div class="g-wrap app-footer">
      <div class="app-footer-left">
        Copyright © 2021, Shenzhen Zhilin Technology Co., Ltd. All rights reserved.
      </div>
      <div class="app-footer-right">
        <span>联系我们</span>
        <el-tooltip class="item" popper-class="footer-concat" effect="light" placement="top">
          <div slot="content" style="width:200px;height:200px;">
            <img style="width:100%;height:100%;object-fit: cover;" src="@/assets/images/zxj_qrcode.jpg" alt />
          </div>
          <span class="iconfont iconweixin icon-item"></span>
        </el-tooltip>
        <el-tooltip class="item" popper-class="footer-concat" effect="light" placement="top">
          <div slot="content">
            <span>联系电话：0755-21030021</span>
          </div>
          <span class="iconfont icondianhua icon-item"></span>
        </el-tooltip>
        <el-tooltip class="item" popper-class="footer-concat" effect="light" placement="top">
          <div slot="content">
            <span>邮箱：market@vzhilin.com</span>
          </div>
          <span class="iconfont iconyouxiang icon-item"></span>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterInfo'

}
</script>

<style lang="scss" scoped>
.app-footer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  &-right {
    display: flex;
    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    .item {
      width: 32px;
      height: 32px;
      margin-left: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #eaeaea;
      border-radius: 50%;
      &:hover {
        background-color: #598DF3;
        color: #FFFFFF;
      }
    }
  }
}
.footer-concat {
  border: 1px solid #979899 !important;
}
// .icon-item {
// }
</style>